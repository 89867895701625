<template>
  <div class="content-layout-fixer">
    <div class="wrapper">
      <Header :percent="headerData.totalViewingPercent" :badgeData="headerData.categoryStatuses" />
      <div class="banner">
        <swiper
          :options="sliderOptions"
          ref="swiperId"
          @ready="pushDLEvent()"
          @slideChange="changeSwiperIndex"
        >
          <swiper-slide :key="index" v-for="(banner, index) in academyData.banners">
            <BrandVimeoPlayer
              v-if="banner.url"
              :id="`${banner.url}`"
              ref="vimeoPlayer"
              :data="{
                embedVideo: `${banner.url}`,
              }"
            >
            </BrandVimeoPlayer>
            <BrandMediaContentBox
              v-else-if="getBannerImageUrl"
              :mediaSrc="getBannerImageUrl"
              :aspectRatios="media.aspect.by_16_9"
            >
            </BrandMediaContentBox>
          </swiper-slide>
          <div class="swiper-button-next" slot="button-next"></div>
          <div class="swiper-button-prev" slot="button-prev"></div>
        </swiper>
        <VueText
          v-if="academyData.banners && academyData.banners[this.activeSlideIndex].description"
          sizeLevel="9"
          weightLevel="3"
          class="description"
        >
          {{ academyData.banners[this.activeSlideIndex].description }}
        </VueText>
      </div>
      <div class="section">
        <VueHeadline level="1" class="headline">Uzmanlık Videoları</VueHeadline>
        <div class="section-card-wrapper">
          <router-link
            v-for="category in academyData.categories"
            :key="category.id"
            :to="{
              name: 'Learn',
              params: {
                id: category.id,
                name: slugify(category.name),
              },
            }"
          >
            <div class="card">
              <div class="card-inner">
                <img
                  :class="['card-img', category.completedStep > 0 && 'active']"
                  :src="
                    category.completedStep !== category.totalStep
                      ? category.inCompletedBackgroundUrl
                      : category.completedBackgroundUrl
                  "
                  :alt="category.name"
                />
                <div
                  :class="['card-step-bar', category.completedStep > 0 && 'active']"
                  v-if="category.completedStep !== category.totalStep"
                >
                  <div class="step-bar">
                    <div
                      v-for="index in category.totalStep"
                      :key="index"
                      :class="[{ active: category.completedStep >= index }, 'step']"
                    ></div>
                  </div>
                  <span class="step-number">{{
                    category.completedStep + '/' + category.totalStep
                  }}</span>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div class="link-btns">
        <BrandButton
          v-if="customerDetail && customerDetail.isEOrderCustomer"
          class="link-btn order"
          id="link_btn_order"
          @click="openEOrder"
        />
        <!-- <BrandButton class="link-btn game" @click="otpCrush" /> -->
        <BrandButton
          class="link-btn faq"
          as="router-link"
          :target="'/secure/tutun-akademi/sss'"
          :outlined="true"
        />
        <!-- <BrandButton
          class="link-btn contact"
          as="router-link"
          :target="'/secure/geribildirim'"
          :outlined="true"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton';
import VueText from '@/components/shared/VueText/VueText.vue';
import Header from '@/components/brand/Headers/OtpHeader.vue';
import { Common, Otp } from '@/services/Api/index';
import BrandVimeoPlayer from '@/components/brand/BrandVimeoPlayer/BrandVimeoPlayer.vue';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';
import BrandMediaContentBox from '@/components/brand/BrandMediaContentBox/BrandMediaContentBox.vue';
import MEDIA_CONSTANTS from '@/constants/media.constants.js';
import gtmUtils from '@/mixins/gtmUtils.js';
import StorageProps from '@/mixins/storageProps.js';

export default {
  name: 'Otp',
  components: {
    VueHeadline,
    BrandButton,
    VueText,
    Header,
    BrandVimeoPlayer,
    swiper,
    swiperSlide,
    BrandMediaContentBox,
  },
  mixins: [gtmUtils, StorageProps],
  data() {
    return {
      headerData: {},
      academyData: {},
      sliderOptions: {
        slidesPerView: 1,
        centeredSlides: true,
        resistance: true,
        resistanceRatio: 0.7,
        watchOverflow: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      activeSlideIndex: 0,
    };
  },
  created() {
    Otp.getHeaderData({}).then(res => {
      if (res.data && res.data.Data.header) {
        this.headerData = res.data.Data.header;
        this.$store.dispatch('app/setOtpCategoryStatuses', this.headerData.categoryStatuses);
        if (window.insider_object?.user?.custom)
          window.insider_object.user.custom.categoryStatuses = this.headerData.categoryStatuses;
      }
    });
    Otp.getAcademyData({}).then(res => {
      if (res.data && res.data.Data && res.data.Data.academy) {
        this.academyData = res.data.Data.academy;
      }
    });
  },
  computed: {
    calcDasharray() {
      let percent = 65;
      return (percent * 58) / 100 + ' ' + (75 - (percent * 58) / 100);
    },
    media() {
      return {
        aspect: MEDIA_CONSTANTS.MEDIA_ASPECT_RATIOS,
      };
    },
    getBannerImageUrl() {
      const baseUrl = process.env.VUE_APP_API_BASE_URL.includes('qa')
        ? 'qa'
        : process.env.VUE_APP_API_BASE_URL.includes('dev')
        ? 'dev'
        : 'pmaktif';

      if (baseUrl == 'pmaktif') {
        return `https://content.pmaktif.com/Files/Slider/Tütün_Ak._Hediye_Banner_Web.jpg`;
      }

      return `https://content-${baseUrl}.pmaktif.com/Files/Slider/Tütün_Ak._Hediye_Banner_Web.jpg`;
    },
  },
  methods: {
    changeSwiperIndex() {
      this.activeSlideIndex = this.$refs.swiperId.swiper.activeIndex;
      // this.pushDLEvent();
    },
    pushDLEvent() {
      if (this.academyData.banners?.length) {
        let sw = this.$refs['swiperId'];
        let index = sw.swiper.activeIndex;
        this.pushDataLayerEvent('slide', this.academyData.banners[index]);
      }
    },
    slugify(text) {
      var trMap = {
        çÇ: 'c',
        ğĞ: 'g',
        şŞ: 's',
        üÜ: 'u',
        ıİ: 'i',
        öÖ: 'o',
      };
      for (var key in trMap) {
        text = text.replace(new RegExp('[' + key + ']', 'g'), trMap[key]);
      }
      return text
        .replace(/[^-a-zA-Z0-9\s]+/gi, '') // remove non-alphanumeric chars
        .replace(/\s/gi, '-') // convert spaces to dashes
        .replace(/[-]+/gi, '-') // trim repeated dashes
        .toLowerCase();
    },
    openEOrder() {
      this.pushDataLayerEvent('ecommerce');
      this.pushDataLayerEvent('on_siparis_click', {
        location: 'tütün akademi',
      });
      Common.getEcommerceUrl().then(res => {
        const {
          Data: { url },
        } = res.data;
        if (url) {
          window.location = url;
        }
      });
    },
    otpCrush() {
      this.$router.push({
        path: '/secure/play-and-win/detail',
        query: { iframeGame: 1, title: 'Kıran Kırana' },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  width: 100%;
  padding-left: 20px;
  background-color: transparent;
}

.headline {
  font-size: 30px;
}
.banner {
  border-bottom: 1px solid #b4c2d3;
  margin-top: 20px;
}
.section {
  margin-top: 59px;

  .section-card-wrapper {
    min-height: 272px;
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(3, 321px);
    grid-gap: 20px;
    .card {
      max-height: 272px;
      border: 1px solid #b4c2d3;
      .card-inner {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    }
  }
}
.description {
  line-height: 1.42;
  padding-top: 30px;
  padding-bottom: 30px;
}
.link-btns {
  max-width: 493px;
  margin: 50px auto 108px;
  .link-btn {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.33;
    color: #1e8fff;
    height: 60px !important;
    background-size: cover;
    background-position: center;
    &.order {
      background-image: url('~@/assets/otp/bg/e-order_btn_bg.png');
    }
    &.contact {
      background-image: url('~@/assets/otp/bg/contact_btn_bg.png');
    }
    &.faq {
      background-image: url('~@/assets/otp/bg/faq_btn_bg.png');
    }
    &.game {
      background-image: url('~@/assets/otp/bg/otp_crash_btn_bg.png');
    }
  }
}

.card-img {
  width: 100%;
  filter: grayscale(1);
  &.active {
    filter: grayscale(0);
  }
}
.card-step-bar {
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #f6f5ee;
  &.active {
    background-color: #fff;
    .step {
      &:after {
        background-color: #fff;
      }
    }
    .step-number {
      color: #c60000;
    }
  }
}
.step-bar {
  width: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  .step {
    height: 3px;
    background-color: #ebebeb;
    position: relative;
    flex: 1;
    &.active {
      background-color: #c60000;
      &:after {
        border-color: #c60000;
        background-color: #c60000;
      }
    }
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      width: 14px;
      height: 14px;
      border: 2px solid #ebebeb;
      border-radius: 7px;
      background-color: #f6f5ee;
      transform: translateY(-46%);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 500;
      color: #ebebeb;
    }
    &:last-child {
      &:after {
        content: 'Quiz';
        width: 40px;
        right: -20px;
        padding: 8px;
        border-radius: 9px;
      }
    }
  }
}
.step-number {
  font-size: 14px;
  font-weight: bold;
  color: #5a5951;
  margin-left: 30px;
}
a {
  text-decoration: none;
}
</style>
