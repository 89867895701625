export const MEDIA_CONTENT_TYPES = {
  image: 'image',
  video: 'video',
  embed: 'embed',
};

export const MEDIA_ASPECT_RATIOS = {
  by_1_1: '1by1',
  by_4_3: '4by3',
  by_16_9: '16by9',
  by_14_9: '14by9',
  by_21_9: '21by9',
};

export default {
  MEDIA_CONTENT_TYPES,
  MEDIA_ASPECT_RATIOS,
};
